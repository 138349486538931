export const SignInIcon = ({ className = '', color = '#FF6838' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect width="28" height="28" rx="6" fill={color} />
      <path
        d="M17.5455 19.451V18.1783C17.5455 17.5032 17.2773 16.8557 16.8 16.3784C16.3226 15.901 15.6751 15.6328 15 15.6328H10.5455C9.87037 15.6328 9.22292 15.901 8.74555 16.3784C8.26818 16.8557 8 17.5032 8 18.1783V19.451"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7725 13.0909C14.1783 13.0909 15.318 11.9513 15.318 10.5455C15.318 9.13964 14.1783 8 12.7725 8C11.3667 8 10.2271 9.13964 10.2271 10.5455C10.2271 11.9513 11.3667 13.0909 12.7725 13.0909Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0913 11.1875V15.0057"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9998 13.0938H18.1816"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
