import { useEffect, useCallback } from 'react';

// type StorageObject = Record<string, unknown>;

export enum KnownStorageKeys {
	Sessions = 'Sessions',
	Messages = 'Messages',
	Feedbacks = 'Feedbacks',
	ForceRefreshVersion = '1',
	ForceRefreshKey = 'forceRefresh',
	UserEmailStored = 'UserEmailStored',
	Wishlists = 'Wishlists',

	SideNavigationCollapsedState = 'side-nav-state',

	ProductToSave = 'ProductToSave',
	RecipientToSave = 'RecipientToSave'
}

export type UseStorageHook = () => {
	store: <T>(key: string, value: T) => void;
	retrieve: <T>(key: string) => T | null;
	clean: (key: string) => void;
};

export const useStorage: UseStorageHook = () => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const forceRefreshVersion = localStorage.getItem(
				KnownStorageKeys.ForceRefreshKey
			);

			if (forceRefreshVersion !== KnownStorageKeys.ForceRefreshVersion) {
				localStorage.clear();
				localStorage.setItem(
					KnownStorageKeys.ForceRefreshKey,
					KnownStorageKeys.ForceRefreshVersion
				);
			}
		}
	}, []);

	const store = useCallback(function store<T>(key: string, value: T): void {
		if (typeof window !== 'undefined') {
			window.localStorage.setItem(String(key), JSON.stringify(value));
		}
	}, []);

	const retrieve = useCallback(function retrieve<T>(key: string): T | null {
		if (typeof window !== 'undefined') {
			const value = window.localStorage.getItem(String(key));
			if (value) {
				return JSON.parse(value);
			}
		}
		return null;
	}, []);

	const clean = useCallback((key: string): void => {
		if (typeof window !== 'undefined') {
			window.localStorage.removeItem(key);
		}
	}, []);

	return {
		store,
		retrieve,
		clean
	};
};
