import { MouseEvent, useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { FiEdit3, FiMoreHorizontal } from 'react-icons/fi';
import { IoMdCheckmark } from 'react-icons/io';
import styles from './editable-item.module.scss';

interface IEditableItemProps {
  className?: string;
  mode?: 'default' | 'menu';
  id?: string;
  value?: string;
  active?: boolean;
  isSkeleton?: boolean;
  onRemove?: () => void;
  onSave?: (value: string) => void;
}

export const EditableItem: React.FC<IEditableItemProps> = ({
  className,
  mode = 'default',
  active,
  id,
  value,
  isSkeleton,
  onRemove,
  onSave,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (!isEdit) {
      setInputValue(value);
    }
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onLinkClick = (e: MouseEvent) => {
    if (!isEdit) {
      open(`/chat/${id}`, '_self');
    }
  };

  const onEditClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsEdit(true);
    inputRef.current?.focus();
  };

  const onRemoveClick = (e: MouseEvent) => {
    e.stopPropagation();
    onRemove?.();
  };

  const onSaveClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (inputValue) {
      setIsEdit(false);
      setShowMenu(false);
      onSave?.(inputValue);
    }
  };

  const onCloseClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsEdit(false);
    setShowMenu(false);
    if (value) {
      setInputValue(value);
    }
  };

  return (
    <li
      className={`${styles.item} ${className} ${active ? 'active' : ''} ${
        isSkeleton ? styles.listItemSkeleton : ''
      }`}
      onClick={onLinkClick}
    >
      {isSkeleton ? (
        <div className={`${styles.name} ${styles.skeleton}`}>
          <div className={styles.stub}>
            <div className={styles.skeletonOverlay}></div>
          </div>
        </div>
      ) : (
        <input
          ref={inputRef}
          className={`${styles.name} ${isEdit ? styles.editable : ''}`}
          title={value}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {!isSkeleton ? (
        <div className={styles.actions}>
          {isEdit ? (
            <>
              <IoMdCheckmark
                className={`${styles.actionIcon}`}
                onClick={onSaveClick}
              ></IoMdCheckmark>
              <AiOutlineClose
                className={`${styles.actionIcon}`}
                onClick={onCloseClick}
              ></AiOutlineClose>
            </>
          ) : mode === 'default' ? (
            <>
              <FiEdit3
                className={`${styles.actionIcon} ${styles.editIcon} actionIcon`}
                onClick={onEditClick}
              ></FiEdit3>
              <BsTrash
                className={`${styles.actionIcon} actionIcon`}
                onClick={onRemoveClick}
              ></BsTrash>
            </>
          ) : (
            <>
              <FiMoreHorizontal
                className={`${styles.actionIcon} actionIcon`}
                data-tooltip-id="editable-item"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu((value) => !value);
                }}
              ></FiMoreHorizontal>
              {showMenu ? (
                <>
                  <div
                    className={styles.backdrop}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu(false);
                    }}
                  ></div>
                  <ul className={`${styles.popupActions} editableItemPopupAction`}>
                    <li className={styles.popupItem} onClick={onEditClick}>
                      <FiEdit3
                        className={`${styles.actionIcon} ${styles.editIcon} actionIcon`}
                      ></FiEdit3>
                      <span>Rename search</span>
                    </li>
                    <li className={styles.popupItem} onClick={onRemoveClick}>
                      <BsTrash
                        className={`${styles.actionIcon} actionIcon`}
                      ></BsTrash>
                      <span>Delete search</span>
                    </li>
                  </ul>
                </>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </li>
  );
};
