import { useBreakpoint } from 'ollie-common/hooks/breakpoint';
import { ReactNode, useEffect, useRef } from 'react';
import { useNav } from '../../hooks/use-nav.hook';

import styles from './navigation-container.module.scss';
import { SideNavigation } from '../side-navigation/side-navigation.component';
import { TopNavigation } from '../top-navigation';

interface INavigationContainerParams {
  children?: ReactNode;
  className?: string;
  isBeta?: boolean;
  homePath?: string;
  scrollHandler?: {
    navigationBarOnTop?: boolean;
    handleScroll?: () => void;
  };
  onMobile?: (state: boolean) => void;
}

export const NavigationContainer: React.FC<INavigationContainerParams> = ({
  className,
  children,
  isBeta,
  homePath,
  scrollHandler,
  onMobile,
}) => {
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);
  const { breakpoints } = useBreakpoint();

  useEffect(() => {
    if (breakpoints) {
      onMobile?.(breakpoints <= 700);
    }
  }, [onMobile, breakpoints]);

  const { navigationBarOnTop, handleScroll } = useNav(scrollTargetRef);

  const calcWithBackground = (
    scrollHandler
      ? typeof scrollHandler?.navigationBarOnTop === undefined
      : true
  )
    ? !navigationBarOnTop
    : !scrollHandler?.navigationBarOnTop;

  return (
    <main className={`${styles.container} ${className}`}>
      <TopNavigation
        className={styles.topNav}
        withBackground={calcWithBackground}
        isBeta={isBeta}
        homePath={homePath}
      />
      <div className={styles.sideNavWrapper}>
        <SideNavigation homePath={homePath} className={styles.sideNav} />
      </div>
      <div
        className="navigationContainerScrollable"
        ref={scrollTargetRef}
        onScroll={scrollHandler?.handleScroll || handleScroll}
      >
        {children}
      </div>
    </main>
  );
};
