import { RefObject, useEffect, useLayoutEffect, useState } from 'react';

interface IUseNavProps {
  navigationBarOnTop: boolean;
  handleScroll: () => void;
}

export const useNav = (
  scrollTargetRef: RefObject<HTMLDivElement | null>,
): IUseNavProps => {
  const [navigationBarOnTop, setNavigationBarOnTop] = useState(true);

  useEffect(() => {
    if (scrollTargetRef && scrollTargetRef.current) {
      setNavigationBarOnTop(scrollTargetRef?.current?.scrollTop === 0);
    }
  }, [scrollTargetRef]);

  const handleScroll = () => {
    if (scrollTargetRef && scrollTargetRef.current) {
      setNavigationBarOnTop(scrollTargetRef.current.scrollTop === 0);
    }
  };

  return {
    navigationBarOnTop,
    handleScroll,
  };
};
