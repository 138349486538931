import { useCallback } from 'react';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionEntity } from 'charlie-workflows/contracts/sessions';
import { useHttp } from '../http/http.service';

export type UseSessionsApiHook = () => {
	updateSession: (
		sessionId: string,
		sessionName: string
	) => Observable<{ session: SessionEntity }>;
	deleteSession: (sessionId: string) => Observable<void>;
	retrieveSessions: () => Observable<SessionEntity[]>;
};

export const useSessionsApi: UseSessionsApiHook = () => {
	const { makeRequest } = useHttp();

	const updateSession = useCallback(
		(sessionId: string, sessionName: string) => {
			return makeRequest<{ session: SessionEntity }>(
				`/api/sessions/${sessionId}`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ name: sessionName })
				}
			);
		},
		[makeRequest]
	);

	const deleteSession = useCallback(
		(sessionId: string) => {
			return makeRequest<void>(`/api/sessions/${sessionId}`, {
				method: 'DELETE'
			});
		},
		[makeRequest]
	);

	const retrieveSessions = useCallback(() => {
		return makeRequest<{ sessions: SessionEntity[] }>(`/api/sessions`, {
			method: 'GET'
		}).pipe(map((value) => value.sessions));
	}, [makeRequest]);

	return {
		updateSession,
		deleteSession,
		retrieveSessions
	};
};
