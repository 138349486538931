import { useState, useCallback } from 'react';
import { firstValueFrom } from 'rxjs';
import { useSessionsApi } from '../sessions-api';
import { SessionEntity } from 'charlie-workflows/contracts/sessions';

export interface UseSessionsServiceHook {
	sessions: SessionEntity[];
	getSessions(): Promise<SessionEntity[]>;
	updateSession(
		sessionId: string,
		name: string
	): Promise<{ session: SessionEntity }>;
	shallowUpdateSession(sessionId: string, name: string): void;
	deleteSession(sessionId: string): Promise<void>;
}

export const useSessions = (): UseSessionsServiceHook => {
	const [sessions, setSessions] = useState<SessionEntity[]>([]);
	const { retrieveSessions, updateSession, deleteSession } = useSessionsApi();

	const getSessions = useCallback(async () => {
		const sessions = await firstValueFrom<SessionEntity[]>(retrieveSessions());
		setSessions(sessions);
		return sessions;
	}, [retrieveSessions]);

	const updateSessionState = useCallback(
		async (sessionId: string, name: string) => {
			const { session: updatedSession } = await firstValueFrom(
				updateSession(sessionId, name)
			);

			setSessions((prevSessions) => {
				const tempSessions = prevSessions.slice();
				const index = tempSessions.findIndex(
					(session) => session.id === sessionId
				);
				tempSessions[index] = updatedSession;
				return tempSessions;
			});

			return { session: updatedSession };
		},
		[updateSession]
	);

	const shallowUpdateSessionState = useCallback(
		(sessionId: string, name: string) => {
			setSessions((prevSessions) => {
				const updatedSessions = prevSessions.slice();
				const index = updatedSessions.findIndex(
					(session) => session.id === sessionId
				);
				if (index !== -1) {
					updatedSessions[index].name = name;
				}
				return updatedSessions;
			});
		},
		[]
	);

	const deleteSessionState = useCallback(
		async (sessionId: string) => {
			await firstValueFrom(deleteSession(sessionId));
			setSessions((prevSessions) =>
				prevSessions.filter((session) => session.id !== sessionId)
			);
		},
		[deleteSession]
	);

	return {
		sessions,
		getSessions,
		updateSession: updateSessionState,
		shallowUpdateSession: shallowUpdateSessionState,
		deleteSession: deleteSessionState
	};
};
