import { useEffect, useState } from 'react';

export type UseBreakpointHook = () => {
	breakpoints: number | null;
};

export const useBreakpoint: UseBreakpointHook = () => {
	const [breakpoints, setBreakpoints] = useState<number | null>(null);

	useEffect(() => {
		setBreakpoints(window.innerWidth);

		function widthListener() {
			setBreakpoints(window.innerWidth);
		}

		window.addEventListener('resize', widthListener);
		return () => {
			window.removeEventListener('resize', widthListener);
		};
	}, []);

	return {
		breakpoints
	};
};
